document.getElementById("next").addEventListener("click", function () {
  const start = parseInt(document.getElementById("start").value);
  const end = parseInt(document.getElementById("end").value);
  const current =
    parseInt(document.getElementById("result").textContent) || start;
  const diff = end - start;
  const averageIncrement = Math.round(diff / 20);
  const minIncrement = Math.round(averageIncrement / 2);
  const maxIncrement = Math.round(averageIncrement * 1.5);
  const increment = Math.round(
    Math.random() * (maxIncrement - minIncrement) + minIncrement
  );
  let newResult = current + increment;
  if (newResult > end) {
    newResult = end;
  }
  document.getElementById("result").textContent = newResult + "";
});
